/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { SvgIcon } from "@material-ui/core";
import cn from "classnames";
import IconButton from "material-ui/IconButton";
import ClearFix from "material-ui/internal/ClearFix";
import { transitions } from "material-ui/styles";
import * as PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import type { AnalyticSimpleDispatcher } from "~/analytics/Analytics";
import { useAnalyticSimpleActionDispatch } from "~/analytics/Analytics";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import BusyIndicator from "~/components/BusyIndicator";
import ActionButton, { ActionButtonType } from "~/components/Button";
import FilterSearchBox from "~/components/FilterSearchBox";
import { Section } from "~/components/Section/Section";
import type { OctopusTheme } from "~/components/Theme";
import { withTheme } from "~/components/Theme";
import { ThirdPartyIcon, ThirdPartyIconType } from "~/primitiveComponents/dataDisplay/Icon";
import type { Origin } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { Popover } from "~/primitiveComponents/dataDisplay/Popover/Popover";
import { MenuItemButton } from "~/primitiveComponents/navigation/MenuItems/MenuItemButton/MenuItemButton";
import { MenuList } from "~/primitiveComponents/navigation/MenuList/MenuList";
import RequestRaceConditioner from "~/utils/RequestRaceConditioner";
import { noOp } from "~/utils/noOp";
import GitRef from "./GitRef";
const keycode = require("keycode");
const styles = require("./style.less");

const GitIcon = (props: any) =>
    withTheme((theme) => (
        <SvgIcon {...props} htmlColor={theme.iconNeutral} style={{ fill: theme.iconNeutral, width: "0.7rem", height: "auto", ...props.style }} viewBox="0 0 640 1024">
            <path d="M512 192c-71 0-128 57-128 128 0 47 26 88 64 110v18c0 64-64 128-128 128-53 0-95 11-128 29v-303c38-22 64-63 64-110 0-71-57-128-128-128s-128 57-128 128c0 47 26 88 64 110v419c-38 22-64 63-64 110 0 71 57 128 128 128s128-57 128-128c0-34-13-64-34-87 19-23 49-41 98-41 128 0 256-128 256-256v-18c38-22 64-63 64-110 0-71-57-128-128-128z m-384-64c35 0 64 29 64 64s-29 64-64 64-64-29-64-64 29-64 64-64z m0 768c-35 0-64-29-64-64s29-64 64-64 64 29 64 64-29 64-64 64z m384-512c-35 0-64-29-64-64s29-64 64-64 64 29 64 64-29 64-64 64z" />
        </SvgIcon>
    ));

function getStyles(props: GitRefDropDownProps, context: any, theme: OctopusTheme) {
    const spacing = context.muiTheme.baseTheme.spacing;
    const palette = context.muiTheme.baseTheme.palette;
    const { disabled } = props;
    return {
        control: {
            cursor: disabled ? "default" : "pointer",
            height: "100%",
            position: "relative" as const,
            width: "100%",
        },
        icon: {
            width: `1.5rem`,
            height: `1.5rem`,
            padding: 0,
            right: 0,
            top: 0,
            marginTop: 0,
            fill: theme.secondaryText,
        },
        iconChildren: {
            fill: "inherit",
        },
        gitIcon: {
            fill: disabled ? palette.disabledColor : theme.iconNeutral,
        },
        label: {
            display: "flex" as const,
            alignItems: "center" as const,
            color: disabled ? palette.disabledColor : palette.textColor,
            overflow: "hidden" as const,
            opacity: 1,
            position: "relative" as const,
            paddingRight: spacing.desktopGutter,
            textOverflow: "ellipsis" as const,
            paddingLeft: spacing.desktopGutterMini,
        },
        labelWhenOpen: {
            opacity: 0,
            top: spacing.desktopToolbarHeight / 8,
        },
        root: {
            display: "inline-block",
            fontSize: spacing.desktopDropDownMenuFontSize,
            fontFamily: context.muiTheme.baseTheme.fontFamily,
            outline: "none",
            position: "relative",
            transition: transitions.easeOut(),
        },
        rootWhenOpen: {
            opacity: 1,
        },
        buttons: {
            position: "absolute" as const,
            right: 0,
            top: "0.2rem",
        },
        dropDownMenu: {
            display: "block",
            border: `1px solid ${theme}`,
        },
        filter: {
            margin: "0 1rem",
            display: "flex",
            flexDirection: "row" as any,
            alignItems: "center",
        },
        empty: {
            margin: "1rem",
        },
    };
}

interface GitRefDropDownAnalyticsProps {
    dispatchAction: AnalyticSimpleDispatcher;
}

interface GitRefDropDownProps {
    onChange: (gitRef: GitRefOption) => void;
    items: GitRefOption[];
    totalItems: number;
    value: any;
    empty?: string;
    style?: "grey" | "white";
    disabled?: boolean;
    onFilterChanged?(value: string): Promise<GitRefOption[]>;
    onRequestRefresh(): Promise<void>;
    onCreateBranch: (gitRef: string) => Promise<void>;
}

type GitRefDropDownPropsInternal = GitRefDropDownProps & GitRefDropDownAnalyticsProps;

interface GitRefDropDownState {
    open: boolean;
    anchorElement: any;
    filter: string | undefined;
    filteredItems: GitRefOption[] | null;
    isBusySearching: boolean; // UX: Localised refresh indicator pattern.
    isBusyRefreshing: boolean;
    errorMessage?: string;
}

class GitRefDropDown extends React.Component<GitRefDropDownPropsInternal, GitRefDropDownState> {
    static muiName = "DropDownMenu";
    static contextTypes = {
        muiTheme: PropTypes.object.isRequired,
    };
    private searchRaceConditioner = new RequestRaceConditioner();

    constructor(props: GitRefDropDownPropsInternal) {
        super(props);
        this.state = {
            open: false,
            anchorElement: null,
            filter: undefined,
            filteredItems: null,
            isBusySearching: false,
            isBusyRefreshing: false,
        };
    }

    private firstMenuNode = React.createRef<HTMLButtonElement>();
    private popoverContentRef: HTMLElement | null = null;
    rootNode = undefined as any;
    arrowNode = undefined as any;

    componentDidMount = () => this.setWidth();

    componentDidUpdate = () => this.setWidth();

    handleTouchTapControl = (event: React.MouseEvent) => {
        event.preventDefault();
        this.setState({
            open: !this.state.open,
            anchorElement: this.rootNode,
            errorMessage: "",
        });
    };

    handleKeyDown = (event: React.KeyboardEvent<{}>) => {
        switch (keycode(event)) {
            case "up":
            case "down":
            case "space":
            case "enter":
                event.preventDefault();
                this.setState({
                    open: true,
                    anchorElement: this.rootNode,
                    errorMessage: "",
                });
                break;
        }
    };

    handleFilterKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        switch (keycode(event)) {
            case "esc":
                this.close();
                break;
            case "down":
                if (this.firstMenuNode.current) {
                    this.firstMenuNode.current.focus();
                }
                break;
        }
    };

    onBranchSelected(item: GitRefOption) {
        this.props.dispatchAction("Switch Branch");
        this.setState(
            {
                open: false,
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(item);
                }

                this.close();
            }
        );
    }

    close = () => {
        this.setState(
            {
                open: false,
                //filter: undefined, //UX: review - we should refactor this Popover component so all filtering can be unmounted when we close.
                //filteredItems: null,
            },
            () => {
                // We shouldn't need to do this because the Popover already returns focus to the previously focused element
                // However, we currently need to do this because we aren't correctly using focusable button elements as our anchor elements
                // If we fix that up, we can remove this manual focusing code
                // See this comment: https://github.com/OctopusDeploy/OctopusDeploy/blob/02aa8e35966221f67ef38c4dc5ea4401060d5474/newportal/app/areas/projects/components/GitRefDropDown/GitRefDropDown.tsx#L329-L330
                // Note that adding a tabIndex={0} is not sufficient because this means there are two focusable elements instead of 1 (the icon button is also a button)
                const dropArrow = this.arrowNode;
                // eslint-disable-next-line react/no-find-dom-node
                const dropNode = ReactDOM.findDOMNode(dropArrow) as HTMLElement;
                dropNode.focus();
                dropArrow.setKeyboardFocus(true);
            }
        );
    };

    private handleFilterChanged = async (value: string) => {
        // Reset any filteredItems we're currently viewing _before_ the search begins, otherwise you end up staring at stale items while a search is happening / feels wrong.
        this.setState({ filter: value, filteredItems: [], isBusySearching: true });
        this.props.dispatchAction("Search for Branch");
        await this.searchRaceConditioner.avoidStaleResponsesForRequest(this.getFilteredResults(value), (filteredItems) => {
            this.setState({ filteredItems, isBusySearching: false });
        });
    };

    private getFilteredResults = async (value: string) => {
        let filteredItems: GitRefOption[] = [];
        if (this.props.onFilterChanged) {
            filteredItems = await this.props.onFilterChanged(value);
        } else {
            filteredItems = this.props.items.filter((item) => {
                return item.text.toLowerCase().search(value.toLowerCase()) !== -1;
            });
        }
        return filteredItems;
    };

    private onRequestRefresh = async () => {
        this.props.dispatchAction("Fetch Branch");
        await this.doBusyTaskForRefreshing(this.props.onRequestRefresh);
    };

    private renderFetch() {
        return (
            <div>
                <div className={styles.fetchButtonContainer}>
                    <ActionButton type={ActionButtonType.Secondary} label="FETCH" title="Fetch the latest branches from your configured remote repository" onClick={this.onRequestRefresh} className={styles.fetchButton} />
                </div>
                {this.state.isBusyRefreshing && <BusyIndicator show={true} />}
            </div>
        );
    }

    private renderDropDown = (theme: OctopusTheme) => {
        const anchorOrigin: Origin = {
            vertical: "bottom",
            horizontal: "left",
        };

        const { items, value, disabled } = this.props;
        const { anchorElement, open, errorMessage } = this.state;

        const { prepareStyles } = this.context.muiTheme;
        const inlineStyles = getStyles(this.props, this.context, theme);

        const activeItems = this.state.filteredItems ?? items;

        const style = this.props.style ?? "grey";
        const className = styles["dropDownMenu-" + style];

        const onCreateBranchClick = async (e: { preventDefault: () => void }) => {
            e.preventDefault();
            try {
                const filter = this.state.filter ?? "";
                await this.doBusyTaskForRefreshing(async () => await this.props.onCreateBranch(filter));
                this.close();
                this.setState({ filter: "", errorMessage: "" });
                await this.doBusyTaskForRefreshing(async () => await this.handleFilterChanged(""));
            } catch (error) {
                this.setState({ errorMessage: error.ErrorMessage });
            }
        };

        return (
            <div
                ref={(node) => {
                    this.rootNode = node;
                }}
                className={cn(styles.dropDownMenu, className)}
                style={prepareStyles(Object.assign({}, inlineStyles.root, open && inlineStyles.rootWhenOpen))}
            >
                {/*This should ideally be a button html element (semantic html is much better for accessibility)
                This breaks the styling enough that I didn't want to commit to this change right now in the short time that I have.*/}
                <div role="button" onClick={disabled ? noOp : this.handleTouchTapControl} aria-label="Switch branch">
                    <ClearFix style={inlineStyles.control}>
                        <div style={inlineStyles.label} className={styles.label}>
                            <GitIcon className={styles.gitIcon} style={inlineStyles.gitIcon} />
                            <GitRef className={styles.value} value={value} />
                        </div>
                        <div style={inlineStyles.buttons} className={styles.buttonDropDown}>
                            <IconButton
                                disabled={disabled}
                                onKeyDown={disabled ? noOp : this.handleKeyDown}
                                ref={(node) => {
                                    this.arrowNode = node;
                                }}
                                style={Object.assign({}, inlineStyles.icon)}
                                iconStyle={inlineStyles.iconChildren}
                                {...{ "aria-label": "ToggleDropDown" }}
                            >
                                <ThirdPartyIcon iconType={ThirdPartyIconType.ArrowDropDown} />
                            </IconButton>
                        </div>
                    </ClearFix>
                </div>
                <Popover anchorOrigin={anchorOrigin} anchorEl={anchorElement} open={open} onClose={this.close} className={styles.popoverContainer}>
                    <div style={{ width: "500px" }} ref={(ref) => (this.popoverContentRef = ref)}>
                        <div onKeyDown={this.handleFilterKeyDown} style={inlineStyles.filter}>
                            <FilterSearchBox placeholder={"Search or create a branch..."} autoFocus={true} value={this.state.filter} onChange={this.handleFilterChanged} fullWidth={true} error={this.state.errorMessage ?? ""} />
                        </div>
                        {this.state.isBusySearching && (
                            <Section>
                                <BusyIndicator show={this.state.isBusySearching} inline={true} />
                            </Section>
                        )}
                        {activeItems.length === 0 && !this.state.isBusySearching && !this.state.isBusyRefreshing && (
                            <div style={inlineStyles.empty}>
                                <a href="#" onClick={onCreateBranchClick}>
                                    Create <strong>{this.state.filter}</strong> from <strong>{value}</strong>
                                </a>
                            </div>
                        )}
                        {activeItems.length > 0 && (
                            <div className={styles.menuListScrollContainer}>
                                <MenuList accessibleName={"Git ref"}>
                                    {activeItems.map((item, index) => {
                                        const isSelected = value === item.value;
                                        const refProps = index === 0 ? { ref: this.firstMenuNode } : {};
                                        return (
                                            <MenuItemButton key={item.value} isSelected={isSelected} onClick={() => this.onBranchSelected(item)} compact={true} {...refProps}>
                                                {<GitRef value={item.text} />}
                                            </MenuItemButton>
                                        );
                                    })}
                                </MenuList>
                            </div>
                        )}
                        {(activeItems.length < this.props.totalItems && activeItems.length && (
                            <div className={styles.warning}>
                                Displaying {activeItems.length} out of {this.props.totalItems} branches.
                            </div>
                        )) || <></>}
                        {this.renderFetch()}
                    </div>
                </Popover>
            </div>
        );
    };

    // If the popover size expands due to a long branch name, we lock in the the minimum width to the new size of the popover
    // This way, if the branch disappears temporarily (for example, while loading new branches or filtering to a subset of branches)
    // The size of the popover doesn't shrink (and then expand again later), but instead remains constant
    // By setting minWidth, we also allow the popover to expand further if an even longer branch name shows up
    private setWidth = () =>
        // Not sure why we need this requestAnimationFrame.
        // We probably shouldn't need it, but for some reason the popover doesn't seem to be rendered yet during `componentDidUpdate` (nor during a useLayoutEffect)
        // I'm not going to spend time trying to understand and solve this problem right now, so sticking with a requestAnimationFrame for now
        requestAnimationFrame(() => {
            if (this.popoverContentRef) {
                this.popoverContentRef.style.minWidth = `${this.popoverContentRef.clientWidth}px`;
            }
        });

    private doBusyTaskForRefreshing = async (action: () => Promise<void>): Promise<void> => {
        this.setState({ isBusyRefreshing: true });
        try {
            await action();
        } finally {
            this.setState({ isBusyRefreshing: false });
        }
    };

    render() {
        return withTheme((theme) => {
            return this.renderDropDown(theme);
        });
    }
}

function GitRefDropDownWithAnalytics(props: GitRefDropDownProps) {
    const dispatchAction = useAnalyticSimpleActionDispatch();

    return <GitRefDropDown {...props} dispatchAction={dispatchAction} />;
}

export default GitRefDropDownWithAnalytics;
