/* eslint-disable @typescript-eslint/consistent-type-assertions */

import React from "react";
import { useSelector } from "react-redux";
import type { RouteComponentProps } from "react-router";
import { withRouter } from "react-router";
import type { GitRefOption } from "~/areas/projects/components/GitRefDropDown/GitRefOption";
import type { ProjectRouteParams } from "~/areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import type { WithProjectContextInjectedProps } from "~/areas/projects/context";
import { useProjectContext } from "~/areas/projects/context";
import type { ProjectResource, VcsBranchResource, GitPersistenceSettings } from "~/client/resources";
import { HasVersionControlledPersistenceSettings, isVcsBranchResource, ProcessType } from "~/client/resources";
import { repository } from "~/clientInstance";
import ActionButton from "~/components/Button/index";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import FeatureToggleVisibility from "~/components/FeatureToggle/New/FeatureToggleVisibility";
import { ExpandableFormSection } from "~/components/form/index";
import { buildBranchNamesList, getTextValuePairForBranch } from "../../../../utils/BranchHelpers/BranchHelpers";
import GitRefDropDown from "../GitRefDropDown/GitRefDropDown";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";
import type { WithOptionalRunbookContextInjectedProps } from "../Runbooks/RunbookContext";
import { useOptionalRunbookContext } from "../Runbooks/RunbookContext";
const keycode = require("keycode");
const styles = require("./style.less");

interface GlobalConnectedProps {
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
}

async function ensureSelectedAndDefaultBranchesExist(branch: string | VcsBranchResource, collection: GitRefOption[], project: Readonly<ProjectResource>) {
    // Note: If a branch is selected that doesn't exist in the base collection of branches, insert it.
    // This may occur if a branch is searched for that is older than the 'latest n' retrieved by default
    const branchName = isVcsBranchResource(branch) ? branch.Name : branch;
    if (!HasVersionControlledPersistenceSettings(project.PersistenceSettings)) throw new Error("Config as Code: Trying to access a VCS Property on a non-VCS Project.");
    const vcsPersistenceSettings = project.PersistenceSettings;

    let items: GitRefOption[] = collection;
    if (!items.find((item) => item.value === branchName)) {
        const branchResource = isVcsBranchResource(branch) ? branch : await repository.Projects.tryGetBranch(project, branchName);

        // It may be that the current branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (branchResource !== null) {
            items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
        }
    }

    if (!items.find((item) => item.value === vcsPersistenceSettings.DefaultBranch)) {
        const branchResource = await repository.Projects.tryGetBranch(project, vcsPersistenceSettings.DefaultBranch);

        // It may be that the default branch has been deleted directly in the repo. Therefore, only add it if it was found.
        if (branchResource !== null) {
            items = [getTextValuePairForBranch(branchResource, vcsPersistenceSettings), ...items];
        }
    }

    return items;
}

type BranchSelectorProps = WithProjectContextInjectedProps & WithOptionalRunbookContextInjectedProps & GlobalConnectedProps & RouteComponentProps<ProjectRouteParams> & { disabled?: boolean; onCreateBranch?: (newBranchName: string) => Promise<void> };

const BranchSelectorInternal: React.FC<BranchSelectorProps> = (props: BranchSelectorProps) => {
    const [branches, setBranches] = React.useState<GitRefOption[]>([]);
    const [totalBranches, setTotalBranches] = React.useState<number>(0);
    const projectContext = useProjectContext();
    const project = props.projectContext.state.model;

    const onCreateBranch = async (newBranchName: string) => {
        await projectContext.state.projectContextRepository.Branches.createBranch(project, newBranchName, props.projectContext.state.branch?.Name ?? "");
        projectContext.actions.changeBranch(newBranchName);
        props.projectContext.actions.onBranchSelected(project, newBranchName);
    };

    const onChanged = async (gitRefOption: GitRefOption) => {
        // Note: When we have a newly selected branch, all we need to do is push it into our router history
        // Note: The props will then flow back into BranchSelector and its initialization useEffect will be triggered
        // Note: As it has a dependency on props.projectContext.state.branch, which in turn changes with there is a new branchName route match
        projectContext.actions.changeBranch(gitRefOption.value);
    };

    const refresh = async () => {
        const selectedBranch = props.projectContext.state.branch;
        if (!selectedBranch) return;

        await repository.Projects.markAsStale(project);
        const branchResources = await repository.Projects.getBranches(project);
        const items = await ensureSelectedAndDefaultBranchesExist(selectedBranch.Name, buildBranchNamesList(branchResources.Items, project), project);
        setBranches(items);
        setTotalBranches(branchResources.TotalResults);
        props.projectContext.actions.onBranchSelected(project, selectedBranch.Name);
    };

    const search = async (value: string) => {
        const branchResources = await repository.Projects.searchBranches(project, value);
        return buildBranchNamesList(branchResources.Items, project);
    };

    React.useEffect(() => {
        async function retrieveBranches(branch: VcsBranchResource) {
            const branchResources = await repository.Projects.getBranches(project);

            // Note: there is a chance that the resource passed to us isn't in the collection returned by getBranches, as it only returns the latest `n` active branches
            // Note: if it isn't in the collection retrieved, we will insert it here
            setBranches(await ensureSelectedAndDefaultBranchesExist(branch, buildBranchNamesList(branchResources.Items, project), project));
            setTotalBranches(branchResources.TotalResults);
        }

        if (project && project.IsVersionControlled && props.projectContext.state.branch) {
            retrieveBranches(props.projectContext.state.branch);
        }
    }, [project, props.projectContext.state.branch]);

    const isVersionControlled = isVersionControlledProcess(props.projectContext.state.model.IsVersionControlled, props.runbookContext ? ProcessType.Runbook : ProcessType.Deployment, !!props.isConfigurationAsCodeForRunbooksEnabled);
    const emptyElementNecessaryForActionListSpacing = <div />;
    return isVersionControlled && props.projectContext.state.branch ? (
        <>
            <div id="branchSelector" className={styles.selectContainer}>
                <GitRefDropDown
                    value={getSelectedItem(props)}
                    items={branches}
                    totalItems={totalBranches}
                    empty="No branches found"
                    onChange={onChanged}
                    onRequestRefresh={refresh}
                    onFilterChanged={search}
                    disabled={props.disabled}
                    onCreateBranch={props.onCreateBranch ?? onCreateBranch}
                />
            </div>
        </>
    ) : (
        emptyElementNecessaryForActionListSpacing
    );
};

const getSelectedItem = (props: BranchSelectorProps): string => {
    if (!props.disabled && props.projectContext.state.branch) {
        return props.projectContext.state.branch.Name;
    }
    const vcs = props.projectContext.state.model.PersistenceSettings as GitPersistenceSettings;
    return vcs.DefaultBranch;
};

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

export const BranchSelector: React.FC<RouteComponentProps<ProjectRouteParams> & { disabled?: boolean; onCreateBranch?: (newBranchName: string) => Promise<void> }> = (props) => {
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const projectContext = useProjectContext();
    const runbookContext = useOptionalRunbookContext();
    const isFeatureEnabled = useEnabledFeatureToggle("SkunkworksFeatureToggle");
    if (isFeatureEnabled) {
        console.log("Skunkworks Feature Is Available");
    }

    return (
        <div className={styles.branchSelectorContainer}>
            <FeatureToggleVisibility toggle={"SkunkworksFeatureToggle"}>
                <ExpandableFormSection errorKey="Skunk" title="Skunkworks Feature" summary={"This is a secret setting"}>
                    <ActionButton onClick={() => window.alert("What Have You Done!?!")} label="Do Not Press" />
                </ExpandableFormSection>
            </FeatureToggleVisibility>
            <BranchSelectorInternal {...props} projectContext={projectContext} runbookContext={runbookContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} disabled={props.disabled} />
        </div>
    );
};

export default withRouter(BranchSelector);
